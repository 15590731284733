import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedComponent } from 'app/presentation/base/localized.component';

@Pipe({
  name: 'rejectionReason',
  standalone: true,
  pure: false,
})
export class RejectionReasonPipe extends LocalizedComponent implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'NO_STOCK':
      case 'OUT_OF_STOCK':
        return this.trans('CATALOG_PAGE.LOCKED_REQUEST_LIST.REJECTED_STATUS_OUT_OF_STOCK');
      case 'UNMATCHED_DEMAND':
        return this.trans('CATALOG_PAGE.LOCKED_REQUEST_LIST.REJECTED_STATUS_UNMACHED_DEMAND');
      case 'BAD_DELIVERY_RATE':
        return this.trans('CATALOG_PAGE.LOCKED_REQUEST_LIST.REJECTED_STATUS_BAD_DELIVERY_RATE');
      case 'BAD_FULFILMENT_RATE':
        return this.trans('CATALOG_PAGE.LOCKED_REQUEST_LIST.REJECTED_STATUS_BAD_FULFILMENT_RATE');
      default:
        return value;
    }
  }
}
